:root {
  --main-text-color: #36374B;
  --main-bg-color: #e8e6e4;
  --color: 232, 230, 228;
  --primaryShadowColor: 0px 3px 5px 0px rgba(232, 230, 228, 0.46);

  --secondary-text-color: #45465a;
  --secondary-bg-color: #DEDDDB;
  --secondaryShadowColor: 0px 3px 5px 0px rgba(54, 55, 75, 0.46);
}

html, body, #root, .main, .app {
    width: 100%;
    height: 100%;
}

body {
  color: var(--main-text-color);
  font-family: 'Titillium Web', sans-serif;
  background: var(--main-bg-color);
  letter-spacing: -0.5px;
}

body.afternoon{
  background: var(--main-text-color);
  color: var(--main-bg-color);
}

body.afternoon svg{
  fill: var(--main-bg-color);
}

h1 {
  font-size: 1.5rem;
}

a {
  color: inherit;
}
a:visited {
  color: inherit;
}

li {
  line-height: 2;
}
p {
  line-height: 1.5;
}
button, .btn {
  border-radius: 50px;
  padding: 10px 35px;
  text-decoration: none;
  margin: 1rem 0;
  text-align: center;
  color: #3e3e3e;
  position: relative;
  display: inline-block;
  background-color: #ffffff;
}

button:visited, .btn:visited {
  color: #3e3e3e;
}

.header {
  padding: 40px;
}

.page-title {
  text-align: center;
  margin: 0;
  font-size: 0.8rem;
}

.page-title a {
  text-decoration: none;
}

.start-page {
  text-align: center;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

.start-page-content {
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.start-page-button {
  margin-top: 3rem;
}

.pick-page {
  padding: 2rem 1rem;

}
.pick-page-content {
  margin-top: 4rem;
}

.station-picker {
  margin-bottom: 2rem;
}

.react-autosuggest__input {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  padding-left: 0;
  padding-bottom: 0;
  color: var(--main-text-color);
  padding-right: 0;
  width: 100%;
}

.afternoon .react-autosuggest__input {
  color: var(--main-bg-color);
}

.large-number {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 4px;
}

.time-table {
  padding: 0.5rem 0;
}

.train {
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  border-radius: 3px;
  background-color: var(--secondary-bg-color);
  position: relative;
}

.afternoon .train {
  background-color: var(--secondary-text-color);
}

.time {
  width: 22%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.actual-time {
  font-size: 1.6rem;
  font-weight: bold;
}

.warning {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.warning svg {
  height: 1.7rem;
  fill: inherit;
}

.is-canceled .warning svg {
  fill: #CE5F0C;
}

.train-information {
  border-left: 1px solid var(--main-text-color);
  margin-left: 20px;
  padding-left: 1rem;
  width: 60%;
}

.afternoon .train-information {
  border-left: 1px solid var(--main-bg-color);
}

.train-information p {
  margin: 0;
  font-size: 0.9rem;
}

.train-information .track {
  font-size: 1.5rem;
  font-weight: bold;
}

.train-information .canceled-headline {
  font-size: 1.4rem;
  font-weight: bold;
}

.train.is-canceled {
  color: #CE5F0C;
}

.train.is-canceled .train-information {
  border-left-color: #CE5F0C;
}

.train.is-canceled .time {
  text-decoration: line-through;
}

.train.is-delayed .old-time {
  text-decoration: line-through;
  opacity: 0.5;
}

.time-table-header {
  padding: 1rem;
  padding-left: 2rem;
  background: var(--secondary-bg-color);
  position: sticky;
  top: 0;
  box-shadow: var(--primaryShadowColor);
  z-index: 1;
}

.afternoon .time-table-header {
  background: var(--secondary-text-color);
  box-shadow: var(--secondaryShadowColor);
}

.time-table-header h3 {
  font-size: 1.8rem;
  margin: 0;
  line-height: 1.2;
}

.time-table-header h5 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: lighter;
}

.reload-button {
  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  margin: 0;
  right: 2rem;
  padding: 0.7rem;
  background: var(--main-text-color);
  z-index: 1;
  border: none;
}

.afternoon .reload-button {
  background: var(--main-bg-color);
  border: none;
  display: flex;
}

.reload-button svg {
  fill: var(--main-bg-color);
  height: 1.5rem;
}

.afternoon .reload-button svg {
  fill: var(--main-text-color);
}

.route-information svg {
  transform: rotate(90deg);
  fill: var(--main-text-color);
  height: 1.9rem;
  margin: 0.5rem 0;
}

.afternoon .route-information svg {
  fill: var(--main-bg-color);
}
