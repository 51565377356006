.sk-chasing-dots {
  margin: 100px auto;
  width: 100px;
  height: 100px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-chasingDotsRotate 2s infinite linear;
          animation: sk-chasingDotsRotate 2s infinite linear; }
.sk-chasing-dots .sk-child {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--main-text-color);
  border-radius: 100%;
  opacity: 0.6;
  -webkit-animation: sk-chasingDotsBounce 2s infinite ease-in-out;
          animation: sk-chasingDotsBounce 2s infinite ease-in-out;
}

body.afternoon .sk-chasing-dots .sk-child {
  background-color: var(--main-bg-color);
}
  .sk-chasing-dots .sk-dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;}
@-webkit-keyframes sk-chasingDotsRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes sk-chasingDotsRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@-webkit-keyframes sk-chasingDotsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
@keyframes sk-chasingDotsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
